<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import customerShow from "@/views/pages/customers/show/show.vue";
import CustomTable from "@/components/tables/custom-table.vue";
import CustomerForm from "@/views/pages/customers/forms/customer-form.vue";
import SelectAutocompleteField from "@/components/forms/select-autocomplete-field.vue";
import DateRangeField from '@/components/forms/date-range-field.vue';
import SelectField from '@/components/forms/select-field.vue';
import Status from "@/helpers/general/status";

/**
 * Customers Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    customerShow,
    CustomTable,
    CustomerForm,
    SelectAutocompleteField,
    DateRangeField,
    SelectField
  },
  data() {
    return {
      showModalId:'holder-show-modal',
      formModalId:'customer-form-modal',
      pageHeader:{
        title: "Titulares",
        breadcrumbs: [
          {
            text: "Titulares",
            href: "/holders",
            active: true
          }
        ],
      },
      customer_status: Status.getList('customers'),
      card_types: Status.getList('youth_card_types'),
      operation_types: Status.getList('card_application_operation_types'),
      card_application_paid_status: Status.getList('card_application_paid_status'),
      card_application_statuses: Status.getList('card_application_statuses'),
      shipping_types: Status.getList('shipping_types'),
      revised_status: Status.getList('revised_status'),
      years_old_list: Status.getList('years_old_list'),
      genders: Status.getList('genders'),
      table:{
        url:'/customers',
        params: {
          'with_trashed': 1,
          'customer_type': 'holder'
        },
        fields:[
          { key: "id", label: "Cod", sortable: true },
          { key: "name", label: "Nombre", sortable: true },
          { key: "identification_type.prefix", label: "Tipo de identificación", sortable: false },
          { key: "identification", label: "Identificación", sortable: false },
          { key: "user.email", label: "Email", sortable: false },
          { key: "created_at", label: "Fecha de creación", sortable: true },
        ],
      },
      filters: {
          name: null,
          years_old: null,
          gender: null,
          identification: null,          
          email: null,
          phone: null,          
          province_id: null,
          region_id: null,
          population_id: null,          
          birthdate_range: {
              startDate: null,
              endDate: null
          },
          card_application_params:{
            youth_card_code: null,
            reference: null,
            amount: null,
            card_type: null,
            revised: null,
            paid_status: null,
            shipping_type: null,
            origin_shipping_type: null,
            operation_type: null,
            origin_card_shipping_type_id: null,
            card_shipping_type_id: null,
            status: [],
            payment_date_range: {
                startDate: null,
                endDate: null
            },
            created_at_range: {
                startDate: null,
                endDate: null
            },
            expiration_date_range: {
                startDate: null,
                endDate: null
            },
          },
      },
      formSelectedItemId:null,   
      showSelectedItemId:null,
      clone:false
    };
  },
  methods: {
    showViewModal(item) {
      this.showSelectedItemId = item?.id || null;
      this.$bvModal.show(this.showModalId);
    },
    showFormModal(item) {
      this.formSelectedItemId = item?.id || null;
      this.$bvModal.show(this.formModalId);
    },
    onSuccess(){
      this.$refs.holderTable.refresh();
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="pageHeader?.title" :items="pageHeader?.breadcrumbs" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <customerShow :modal-id="showModalId" :id="showSelectedItemId"></customerShow>
            <CustomerForm :modal-id="formModalId" :id="formSelectedItemId" @onSuccess="onSuccess"></CustomerForm>
            <CustomTable
              :url="table.url"
              :fields="table.fields"
              :params="table.params"
              ref="holderTable"
              :show-view="true"
              :show-edit="true"
              :showSoftDelete="false"
              @edit="showFormModal"
              @show="showViewModal"
              :show-modal-id="showModalId"
              :filters2="filters"
            >
            <template v-slot:filters="{ filters }">
                  <div class="row">
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="name">Nombre</label>
                          <input id="name" v-model="filters.name" type="text" class="form-control"
                              placeholder="Filtrar por nombre" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="identification">Identificación</label>
                          <input id="identification" v-model="filters.identification" type="text"
                              class="form-control" placeholder="Filtrar por identificación" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="youth_card_code"># de carné</label>
                          <input id="youth_card_code" v-model="filters.card_application_params.youth_card_code" type="text"
                              class="form-control" placeholder="Filtrar por # de carné" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="card_type">Modalidad</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="card_types" v-model="filters.card_application_params.card_type"></SelectField>                                        
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="phone">Fecha de solicitud</label>
                          <DateRangeField v-model="filters.card_application_params.created_at_range" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="phone">Fecha de pago</label>
                          <DateRangeField v-model="filters.card_application_params.payment_date_range" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="status">Estado de la solicitud</label>
                          <SelectField label="label" placeholder="Todos" :multiple="true"
                              :options="card_application_statuses" v-model="filters.card_application_params.status"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="status">Estado del pago</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="card_application_paid_status" v-model="filters.card_application_params.paid_status"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="phone">Móvil / teléfono</label>
                          <input id="phone" v-model="filters.phone" type="text" class="form-control"
                              placeholder="Filtrar por móvil ó teléfono" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="email">Email</label>
                          <input id="email" v-model="filters.email" type="text" class="form-control"
                              placeholder="Filtrar por email" />
                      </div>                                    
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="birthdate_range_picker">Fecha de nacimiento</label>
                          <DateRangeField v-model="filters.birthdate_range" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="years_old">Por edad</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="years_old_list" v-model="filters.years_old"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="gender">Género</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="genders" v-model="filters.gender"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="expiration_date_range_picker">Fecha de caducidad</label>
                          <DateRangeField v-model="filters.card_application_params.expiration_date_range" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="reference">Referencia</label>
                          <input id="reference" v-model="filters.card_application_params.reference" type="text" class="form-control"
                              placeholder="Filtrar por referencia" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="amount">Importe</label>
                          <input id="amount" v-model="filters.card_application_params.amount" type="text" class="form-control"
                              placeholder="Filtrar por importe" />
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="status">Tipo de operación</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="operation_types" v-model="filters.card_application_params.operation_type"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="origin_shipping_type">Tipo de creación</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="shipping_types" v-model="filters.card_application_params.origin_shipping_type"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="name">Oficina de creación</label>
                          <SelectAutocompleteField
                              :url="`/card-shipping-types`"
                              :only-ids="true"
                              v-model="filters.card_application_params.origin_card_shipping_type_id"
                              :params="{shipping_type: filters.card_application_params.origin_shipping_type}"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="shipping_type">Tipo de envío</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="shipping_types" v-model="filters.card_application_params.shipping_type"></SelectField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label for="name">Oficina de envío</label>
                          <SelectAutocompleteField
                              :url="`/card-shipping-types`"
                              :only-ids="true"
                              v-model="filters.card_application_params.card_shipping_type_id"
                              :params="{shipping_type: filters.card_application_params.shipping_type}"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Provincia</label>
                          <SelectAutocompleteField
                              :url="`/provinces`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una provincia'"
                              :label="'name'"
                              v-model="filters.province_id"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Comarca</label>
                          <SelectAutocompleteField
                              :url="`/regions`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una comarca'"
                              :label="'fullname'"
                              v-model="filters.region_id"
                              :params="{province_id: filters.province_id}"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                          <label>Población</label>
                          <SelectAutocompleteField
                              :url="`/populations`"
                              :only-ids="true"
                              :multiple="false"
                              :placeholder="'Busca una población'"
                              :label="'fullname'"
                              v-model="filters.population_id"
                              :params="{region_id: filters.region_id}"
                          ></SelectAutocompleteField>
                      </div>
                      <div class="form-group col-12 col-lg-3 col-sm-6">
                        <label for="name">Revisado</label>
                          <SelectField label="label" placeholder="Todos"
                              :options="revised_status" v-model="filters.card_application_params.revised"></SelectField>
                      </div>
                  </div>
              </template>
            </CustomTable>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>